<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link
        to="/"
        class="brand-logo d-flex align-center"
      >
        <v-img
          :src="appLogo"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          class="me-3 "
        ></v-img>

        <h2 class="text--primary">
          {{ appName }}
        </h2>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col
          lg="4"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pa-10 pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <p
                    class="text-2xl font-weight-semibold text--primary mb-2"
                  >
                    Bienvenue sur {{ appName }} !
                  </p>
                  <p class="mb-2">
                    Identifiez-vous pour accéder à votre compte
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form @submit="onSubmit">
                    <v-text-field
                      v-model="email"
                      outlined
                      label="Email"
                      placeholder="Email"
                      hide-details="auto"
                      class="mb-6"
                    ></v-text-field>

                    <v-text-field
                      v-model="password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="Mot de passe"
                      placeholder="Mot de passe"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      hide-details="auto"
                      class="mb-2"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <div
                      class="d-flex align-center justify-space-between flex-wrap"
                    >
                      <v-checkbox
                        hide-details
                        label="Se souvenir de moi"
                        class="mt-0"
                      >
                      </v-checkbox>

                      <!-- forget link -->
                      <a
                        class="ms-3"
                        href="/reset-password"
                      > Mot de passe oublié ? </a>
                    </div>
                    <v-btn
                      block
                      color="primary"
                      type="submit"
                      class="mt-6"
                    >
                      Se connecter
                    </v-btn>
                  </v-form>
                </v-card-text>

                <v-card-text
                  class="d-flex align-center justify-center flex-wrap mt-2"
                >
                  <span
                    v-show="error"
                    class="red"
                  >
                    {{ error }}
                  </span>
                </v-card-text>
                <!-- create new account  -->
                <v-card-text
                  class="d-flex align-center justify-center flex-wrap mt-2"
                >
                  <p class="mb-0 me-2">
                    Nouveau sur {{ appName }} ?
                  </p>

                  <a href="/signup"> Créer votre compte </a>
                </v-card-text>
                <!-- divider -->
                <!-- <v-card-text class="d-flex align-center mt-2">
                  <v-divider></v-divider>
                  <span class="mx-5">or</span>
                  <v-divider></v-divider>
                </v-card-text> -->

                <!-- socail links -->
                <!-- <v-card-actions class="d-flex justify-center">
                  <v-btn
                    v-for="link in socialLink"
                    :key="link.icon"
                    icon
                    class="ms-1"
                  >
                    <v-icon
                      :color="$vuetify.theme.dark ? link.colorInDark:link.color"
                    >
                      {{ link.icon }}
                    </v-icon>
                  </v-btn>
                </v-card-actions> -->
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOffOutline, mdiEyeOutline, mdiFacebook, mdiGithub, mdiGoogle, mdiTwitter } from '@mdi/js'
import themeConfig from '@themeConfig'
import { ref } from '@vue/composition-api'
import store from '@/store'

export default {
  setup() {
    const isPasswordVisible = ref(false)
    const email = ref('')
    const password = ref('')
    const error = ref(null)
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2',
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2',
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff',
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437',
      },
    ]

    const onSubmit = event => {
      event.preventDefault()
      const values = {
        email: email.value,
        password: password.value,
      }
      store.dispatch('user/LOGIN', values).catch(e => {
        switch (e.code) {
          case 'auth/user-not-found':
            error.value = "Cette adresse e-mail n'est attribuée à aucun compte"
            break
          case 'auth/wrong-password':
            error.value = 'Mot de passe est incorrect. Réessayez.'
            break
          default:
            error.value = `Une erreur est survenue (${e.message}). Réessayez.`
            break
        }
      })
    }

    return {
      isPasswordVisible,
      email,
      socialLink,
      error,
      password,

      // Functions
      onSubmit,

      // Icons
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
.red {
  color: red;
}
</style>
